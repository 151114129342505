<template>
  <v-container
    id="curation-list"
    fluid
    tag="section"
  >
    <Filters
      @onFiltersChanged="onFiltersChanged"
    />
    <v-sheet
      elevation="3"
      rounded
    >
      <v-row
        class="pa-4"
        no-gutters
      >
        <v-col
          cols="12"
          md="12"
          class="text-right"
        >
          {{ result.count }} {{ $t('views.curation.forms.labels.nb_items') }}
        </v-col>
      </v-row>
      <v-data-table
        :footer-props="table.footerProps"
        :headers="table.headers"
        :items="result.items"
        :loading="result.loading"
        :options.sync="table.options"
        :page="table.options.page"
        :server-items-length="result.count"
        @update:page="load"
        @update:sort-by="load"
        @update:sort-desc="load"
      >
        <template v-slot:loading>
          <v-sheet
            class="text-center pa-6"
          >
            {{ $t('global.loading') }}
          </v-sheet>
        </template>
        <template v-slot:no-data>
          <v-sheet
            class="text-center pa-6"
          >
            {{ $t('global.no-data') }}
          </v-sheet>
        </template>
        <template v-slot:item.edit="{ item }">
          <v-btn
            icon
            @click.stop="edit(item)"
          >
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.photos="{ item }">
          <v-row>
            <v-col
              v-for="photo in item.photos"
              :key="photo.photo.id"
              class="d-flex child-flex"
              cols="4"
            >
              <v-img
                :src="photo.photo.src.thumbnail"
                aspect-ratio="1"
                class="grey lighten-2"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    />
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.title="{ item }">
          <span>{{ item.title }}</span>
        </template>
        <template v-slot:item.store_name="{ item }">
          <span>{{ item.store.name }}</span>
        </template>
        <template v-slot:item.state="{ item }">
          <StateLabel :item="item" />
        </template>
        <template v-slot:item.price="{ item }">
          <template v-if="item.price?.['@type'] === 'PriceRange'">
            <span class="text-subtitle-1 s">{{ item.price.min|currency }} - {{ item.price.max|currency }}</span>
          </template>
          <template v-else>
            {{ item.price|currency }}
          </template>
        </template>
        <template v-slot:item.pending_at="{ item }">
          {{ item.pending_at | date }}
        </template>
        <template v-slot:item.accepted_at="{ item }">
          {{ item.accepted_at | date }}
        </template>
        <template v-slot:item.rejected_at="{ item }">
          {{ item.rejected_at | date }}<br>
          <template v-if="item.rejection_reason_type">
            <RejectionLabel :item="item" />
          </template>
        </template>
        <template v-slot:item.market_place_permalink="{ item }">
          <v-btn
            v-if="item.market_place_permalink"
            icon
            target="_blank"
            :href="item.market_place_permalink"
          >
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-sheet>
    <Edit :fetch-next-product-id="fetchNextProductId" />
  </v-container>
</template>

<script>
import { getQueryAsArray, getQueryString } from '@/helpers/queryString'
import Edit from '@/views/Curation/Edit'
import EventBus from '@/plugins/event-bus'
import Filters from '@/views/Curation/Filters'
import { mapState } from 'vuex'
import RejectionLabel from '@/components/core/RejectionLabel'
import StateLabel from '@/components/core/StateLabel'

export default {
  name: 'CurationList',
  components: { Filters, StateLabel, RejectionLabel, Edit },
  data() {
    return {
      request: null,
      product_id: null,
      showEditDialog: false,
      table: {
        options: {
          page: +this.$route.query.page || 1,
          itemsPerPage: 30,
          sortBy: [this.$route.query.sortBy || 'pending_at'],
          sortDesc: [this.$route.query.sortDesc || 0],
        },
        headers: [
          {
            text: this.$i18n.t('views.curation.headers.edit'),
            value: 'edit',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.curation.headers.photos'),
            value: 'photos',
            width:220,
            sortable: false,
          },
          {
            text: this.$i18n.t('views.curation.headers.store_name'),
            value: 'store.name',
            sortable: true,
          },
          {
            text: this.$i18n.t('views.curation.headers.title'),
            value: 'title',
            sortable: true,
          },
          {
            text: this.$i18n.t('views.curation.headers.state'),
            value: 'state',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.curation.headers.price'),
            value: 'price',
            sortable: true,
          },
          {
            text: this.$i18n.t('views.curation.headers.description'),
            value: 'description',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.curation.headers.pending_at'),
            value: 'pending_at',
            align: 'right',
            sortable: true,
          },
          {
            text: this.$i18n.t('views.curation.headers.accepted_at'),
            value: 'accepted_at',
            align: 'right',
            sortable: true,
          },
          {
            text: this.$i18n.t('views.curation.headers.rejected_at'),
            value: 'rejected_at',
            align: 'right',
            sortable: true,
          },
          {
            text: '',
            value: 'market_place_permalink',
            align: 'right',
            sortable: false,
          },
          {
            text: '',
            value: 'menu',
            sortable: false,
          },
        ],
        footerProps: {
          'items-per-page-options': [30],
          'show-first-last-page': true,
          'show-current-page': true,
        },
      },
      result: {
        items: [],
        count: 0,
        loading: false,
      },
      filters: {
        query: this.$route.query.query,
        'state_and_reason[]': getQueryAsArray(this.$route.query, 'state_and_reason[]'),
        'store.id': this.$route.query['store.id'],
        'pending_at[after]': this.$route.query['pending_at[after]'],
        'pending_at[before]': this.$route.query['pending_at[before]'],
      },
    }
  },
  computed: {
    ...mapState(['roles']),
    queryString() {
      return 'v3/products?' + getQueryString(
        this.table.options.page,
        this.filters,
        this.table.options.sortBy,
        this.table.options.sortDesc
      )
    },
  },
  watch: {
    queryString: {
      handler() {
        this.load()
      },
    },
    'table.options.page': function(value) {
      this.$router.replace({ name: 'CurationList', query:
          { ...this.filters, page : value } }
      )
    },
  },
  mounted() {
    this.load()
  },
  created() {
    EventBus.$on('CURATION_REFRESH_LIST', () => {
      this.load()
    })
  },
  methods: {
    onFiltersChanged(filters) {
      this.filters = filters
      this.$router.replace({ name: 'CurationList', query:
          { ...this.filters, page : this.table.options.page } })
    },
    cancel() {
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }
    },
    load() {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }
      this.result.loading = true
      this.result.items = []
      this.result.count = 0
      
      this.$axios.get(this.queryString, {
        cancelToken: axiosSource.token,
      })
        .then((response) => {
          this.result.items = response.data['hydra:member']
          this.result.count = response.data['hydra:totalItems']
          this.result.loading = false
        })
        .catch((error) => {
          if (!this.$axios.isCancel(error)) {
            // eslint-disable-next-line no-console
            console.log(error)
            this.result.loading = false
          }
        })
        .finally(() => {
          this.request = null
        })
    },
    edit(product) {
      EventBus.$emit('EDIT_PRODUCT', { productId: product.id, index: this.result.items.indexOf(product) })
    },
    async fetchNextProductId() {
      try {
        const result = await this.$axios.get('v3/products?' + getQueryString(
          this.table.options.page,
          {
            ...this.filters,
            ...{
              itemsPerPage: 1,
              'properties[]': ['id'],
            },
          },
          this.table.options.sortBy,
          this.table.options.sortDesc
        ), {})

        return result.data['hydra:member']?.[0]?.id ?? null
      } catch (error) {
        throw error.response.data['hydra:description'] ?? error.response.data['detail'] ?? 'No context'
      }
    },
  },
}
</script>

<style scoped>
</style>
